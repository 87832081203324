import AppContext, { contextValue } from '~/context'
import { Chatbot, Footer, Hero, Navbar } from '~/components/home'
import React, { FC } from 'react'

import { LayoutProps } from '../../types/LayoutProps'
import { SEOHead } from '~/components/common/seo'
import { SocialShare } from '~/components/common'
import { useRouter } from 'next/router'

const MainLayout: FC<LayoutProps> = ({
  children,
  banners,
  path,
  headerData,
  categories,
}) => {
  const router = useRouter()
  return (
    <AppContext.Provider value={contextValue}>
      <Navbar headerData={headerData} />
      <Chatbot/>
      {/* <Hero banners={banners} /> */}

      {children}
      <SEOHead path={path} />
      <SocialShare />
      {router.pathname !== '/creatorP/signin' && <Footer/>}
    </AppContext.Provider>
  )
}
export default MainLayout
