import Head from "next/head";
import React from "react";
const Meta = ({
  url,
  title,
  description,
  image,
  keywords,
  type,
  twitter,
  noindex
}: any) => {
  return (
    <Head>
      <meta name="theme-color" content="#3c9275" />
      {noindex ? (
        <meta name="robots" content="noindex,follow" />
      ) : (
        <meta name="keywords" content={keywords} />
      )}
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="videoAC" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta itemProp="og:description" content={description} />
      {twitter && (
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:url" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:site" content="@videoAC" />
          <meta name="twitter:creator" content="@videoAC" />
        </>
      )}
    </Head>
  );
};
export default Meta;
