import Head from 'next/head'
import React from 'react'
import { withI18n } from '~/hocs'

const locales = ['en']

const SEOHead = ({ path, lng }) => {
  const hostName = 'https://video-ac.com'
  const fPath = path || ''
  return (
    <Head>
      {lng === 'ja'
        ? (
          <link rel="canonical" href={`${hostName}${fPath}`} />
        )
        : (
          <link rel="canonical" href={`${hostName}/${lng}${fPath}`} />
        )}
      <link rel="alternate" hrefLang="ja" href={`${hostName}${fPath}`} />
      {locales.map((lng) => (
        <link
          key={lng}
          rel="alternate"
          hrefLang={lng}
          href={`${hostName}/${lng}${fPath}`}
        />
      ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${hostName}${fPath}`}
      />
    </Head>
  )
}

export default withI18n(SEOHead)
