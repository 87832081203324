import React, { FC, useRef } from 'react'
import { withI18n, withUser } from '~/hocs'

import { getURLLogin } from '~/utils'

const TextButton: FC<any> = ({ t, lng, isAuthen, className }) => {
  const divRef: any = useRef(null)

  React.useEffect(() => {
    if (divRef && !isAuthen && process.browser) {
      divRef.current.innerHTML = t('common:text_bottom', { href: getURLLogin(lng) })
    }
  }, [])

  return <div className={className} ref={divRef} />

  // if (isAuthen || !process.browser) return <div />
  // return (
  //   <div className={className} dangerouslySetInnerHTML={{ __html: t('common:text_bottom', { href: getURLLogin(lng) }) }} />
  // )
}

export default withUser(withI18n(TextButton))
