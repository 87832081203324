import { AppCommonProps } from 'types/app'
import Link from 'next/link'
import React from 'react'
import { buildHref } from '~/utils'
import { routes } from '~/router'
import { withI18n } from '~/hocs'

const ListTags = ({ lng, tags = [], className }: AppCommonProps) => {
  return (
    <div className={className}>
      <div className="list-top-dl-tags">
        {
          tags.map((t: any) => (
            <Link key={t.id} href={buildHref({ pathname: routes.tag.href, query: { tag: t.keyword }, lng })}>
              {t.keyword}
            </Link>
          ))
        }
      </div>
    </div>
  )
}
export default withI18n(ListTags)
