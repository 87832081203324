import { Button, Col, Container, Row } from 'react-bootstrap'
import { SagaStore, wrapper } from '~/store'
// import SlackService, { SlackColor } from '~/services/SlackService'
import { assetURL, buildHref, pathURL } from '~/utils'
import { loadBanner, loadVideos } from '~/store/index/actions'
import { withI18n, withUser } from '~/hocs'

import { AppCommonProps } from 'types/app'
import { END } from 'redux-saga'
import Head from 'next/head'
import Link from 'next/link'
import ListCategories from '~/components/home/ListCategories'
import ListCollections from '~/components/home/ListCollections'
import ListTags from '~/components/home/ListTags'
import { ListVideos } from '~/components/common'
// import Logger from '~/utils/log'
import MainLayout from '~/layout/MainLayout'
import { Meta } from '~/components/common/seo'
import { NextPage } from 'next'
import { PropsContext } from 'types/redux'
import React from 'react'
import TagService from '~/services/TagService'
import TextButton from '~/components/common/TextButton'
import VideoService from '~/services/VideoService'
import { commonMiddleware } from '~/middleware'
import { loadCategories } from '~/store/category/actions'
import { loadCollections } from '~/store/collection/actions'
import { routes } from '~/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { Hero } from '~/components/home'
import SearchService from '~/services/SearchService'

const Index: NextPage<AppCommonProps> = ({
  banners,
  videos,
  t,
  lng,
  headerData,
  topDownloadVideos,
  latestVideos,
  categories,
  collections,
  topTags,
  isAuthen,
  isPremium,
  recommendVideo
}) => {
  // const metaImageUrl = assetURL('banner/banner_1_576.jpg')
  const metaImageUrl = assetURL('asset/image/image_2023_01_13T08_03_56_571Z.png')

  return (
    <MainLayout banners={banners} headerData={headerData}>
      <Head>
        <title>{t('index:page_title')}</title>
      </Head>
      <Meta
        url={pathURL('', lng)}
        title={t('index:page_title')}
        description={t('index:page_description', { total: 500 })}
        image={metaImageUrl}
        keywords={t('index:page_keywords')}
        type="article"
        twitter={true}
      />
      <Hero banners={banners} />

      {/* <Container fluid className="mt-5 mb-5 px-sm-2 px-md-3 px-lg-4 px-lg-5"> */}
      {/* <Container className="mt-5">
        <TextButton className="my-5" />
        <div>
          <div className="ac-section-title ac-mb-3">
            <h2>{t('most_popular_videos')}</h2>
          </div>
          <ListVideos className="mt-3 ml-md-0 mr-md-0 ml-2 mr-2" videos={topDownloadVideos} isAuthen={isAuthen} isPremium={isPremium} />
          <div className="d-flex flex-row-reverse">
            <Link href={buildHref({ pathname: routes.topdownload.href })}><a className="text-blue hover-underline">{t('see_more_popular')} <i className="fa fa-angle-right" /></a></Link>
          </div>
        </div>
      </Container> */}

      <Container className="mt-5">
        <div>
          <div className="ac-section-title ac-mb-3">
            <h2>{t('latest_videos')}</h2>
          </div>
          <ListVideos className="mt-3 ml-md-0 mr-md-0 ml-2 mr-2" videos={latestVideos} isAuthen={isAuthen} isPremium={isPremium}/>
          <div className="d-flex flex-row-reverse">
            <Link href={buildHref({ pathname: routes.discovery.href, query: { sort_by: '-displayed_at' }, lng })}><a className="text-blue hover-underline">{t('see_more_latest_videos')} <i className="fa fa-angle-right" /></a></Link>
          </div>
        </div>
      </Container>

      <Container className="mt-5">
        <div className="ac-section-title ac-mb-3">
          <h2>{t('category')}</h2>
        </div>
        <ListCategories className="mt-3 ml-md-0 mr-md-0 ml-3 mr-3" categories={categories} />
      </Container>

      <Container className="mt-5">
        <div className="ac-section-title ac-mb-3">
          <h2>{t('most_dl_keyword')}</h2>
        </div>
        <ListTags tags={topTags} />
      </Container>

      <Container className="mt-5">
        <div className="ac-section-title ac-mb-3">
          <h2>{t('most_popular_collections')}</h2>
        </div>
        <ListCollections className="mt-3 ml-md-0 mr-md-0 ml-2 mr-2" collections={collections} />
      </Container>

      <Container className="mt-5 mb-5">
        <div className="ac-section-title ac-mb-3">
          <h2>{t('recommend_video')}</h2>
        </div>
        <ListVideos className="mt-3 ml-md-0 mr-md-0 ml-2 mr-2" videos={recommendVideo} isAuthen={isAuthen} isPremium={isPremium} />
        <div className="flex-center mt-4">
          <Link href={routes.discovery.href} locale={lng}>
            <Button
              className="px-4"
              style={{
                lineHeight: '2.5rem',
                boxShadow: 'none',
                minWidth: '12rem',
                fontWeight: 600,
              }}
            >
              {t('btn_discovery_more')}
            </Button>
          </Link>
        </div>
        <TextButton className="mt-5" />
      </Container>

      <Container className="mt-5" style={{ marginBottom: '5rem' }}>
        {/* <div className="ac-section-title ac-mb-3">
          <h2>ACワークスからのお知らせ</h2>
        </div> */}
        <iframe className="w-100" style={{ height: 220 }} src={`https://www.freebie-ac.jp/admin/news/view/index/video?displayed=3&lang=${lng}`} frameBorder="5" rel="nofollow"/>
      </Container>
    </MainLayout>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(
  async(ctx: PropsContext) => {
    const { store, locale, query } = ctx
    store.dispatch(
      loadBanner({
        pattern_id: 67,
        limit: 2,
      }),
    )
    // store.dispatch(
    //   loadVideos({
    //     limit: query.per_page || 12,
    //     page: query.page,
    //     seed: true,
    //     lng: locale,
    //   }),
    // )
    store.dispatch(
      loadCategories({ lng: locale, preload_videos: true, sort_by: '-dl_count' }),
    )
    store.dispatch(
      loadCollections({ limit: 6, preload_videos: true, sort_by: '-dl_count' }),
    )
    store.dispatch(END)
    let commonProps: any = {}
    let topDownloadVideos: any[] = []
    let latestVideos: any[] = []
    let topTags: any[] = []
    let recommendVideo: any[] = []
    await Promise.all([
      (store as SagaStore).sagaTask?.toPromise(),
      commonMiddleware(ctx).then((res: any) => {
        commonProps = res
      }),
      // VideoService.get(undefined, { seed: false, top_dl_in_week: true, limit: 12 })
      //   .then(({ data, meta }: any) => {
      //     topDownloadVideos = data
      // }),
      VideoService.get(undefined, { sort_by: '-displayed_at', limit: 4, t: Date.now() })
        .then(({ data, meta }: any) => {
          latestVideos = data
      }),
      VideoService.get(undefined, { limit: 12 , seed: true})
      .then(({ data, meta }: any) => {
        recommendVideo = data
      }),
      SearchService.getTopKeyWord({ limit: 25 })
        .then(({ data }: any) => {
          topTags = data
      }),
    ])
    const state = store.getState()

    return {
      props: {
        ...commonProps,
        videosMeta: state.index.videosMeta,
        categories: state.category.categories,
        videos: state.index.videos,
        banners: state.index.banners,
        collections: state.collection.collections,
        topDownloadVideos,
        latestVideos,
        topTags,
        recommendVideo,
        ...(await serverSideTranslations(locale, ['common', 'index'])),
      },
    }
  },
)

export default withUser(withI18n(Index))
